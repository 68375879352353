@import "../style/variables";

.Button,
.Button--inline {
  background: $pink;
  min-height: $button-height;
  letter-spacing: .6px;
  text-align: center;
  padding: 15px 30px;
  font-size: 14px;
  border: 0;
  color: white;
  font-family: 'roboto';
  position: relative;
  z-index: 0;
  transition: all ease-in-out 150ms;
  display: block;
  margin: auto;
  max-width: 360px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    background: darken($pink, 5%);
  }



  &:disabled {
    background: $grey-6;
    cursor: not-allowed;
  }

  &.Secondary {

    background: $green;

    &:hover,
    &:active {
      background: darken($green, 5%);
    }
  }

  &.CTA {
    background: white;
    color: $pink;
    border: 1px solid $pink;
    border-radius: 30px;

    &::before {
      display: block;
      content: "";
      width: 16px;
      height: 16px;
      background:
        linear-gradient($pink, $pink),
        linear-gradient($pink, $pink);
      background-position: center;
      background-size: 100% 2px, 2px 100%;
      background-repeat: no-repeat;
      float: left;
      transition: all ease-in-out 150ms;
    }

    &:hover,
    &:active {
      background: $pink;
      color: white;

      &::before {
        background:
          linear-gradient(white, white),
          linear-gradient(white, white),
          $pink;
        background-position: center;
        background-size: 100% 2px, 2px 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

.Bouton--inline {
  display: inline-block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  min-width: 110px;
}