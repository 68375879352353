@import '../style/variables';

.containerCanTranslate {
  flex-basis: 33.333333%;
  display: flex;
  justify-content: center;
}

.languageCouple {
  display: flex;
  min-width: 10rem;
  margin: 1rem 0.5rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.1rem;
  font-size: 1rem;
  position: relative;

  span {
    padding: 0 0.2rem;
    span {
      font-size: 0.6rem;
    }
  }

  &.VALIDATED {
    border: 1px solid $grey-formfields;
  }

  &.PENDING {
    border: 1px solid $orange;
    cursor: pointer;
    color: $grey-8;
  }
}

.ActionLink:hover,
.ActionLink:focus {
  text-decoration: underline;
}
.iconInfoWarning {
  color: $orange;
}
