.Label {
  padding-left: 5px;
}

.Flag {
  vertical-align: middle;
}

.formControl {
  span + div[class*='-control'] {
    color: #495057;
    background-color: #fff;
    border-color: #368ece;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(26, 73, 107, 0.25);
  }
}
