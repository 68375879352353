@import '../style/variables';
@import '../style/section-options';
@import '../layout/Card.module.scss';

// ==================================== GENERAL ==============================

span, div, p, h1, h2, h3 {
  font-family: 'Roboto';
}

textarea {
  font-family: 'Roboto';
  border-radius: 10px!important;
  color:$blue;
  //border: 1px $blue solid!important;
}

.basic-select {
  border-radius: 10px;
  border: none;
  color: $blue;
  min-width: 160px;
  box-shadow: 0px 3px 6px #00000029;

}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

a:hover {
  text-decoration: none !important;
  color: inherit;
}

p.small {
  font-size: 1rem;
}
.section-panel {
  padding: 15px 15px 10px 15px;
  font-size: 16px;
  background: white;

  .section-panel-title {
    padding: 13px 16px;
    color: $pink;
    border-bottom: 1px solid $grey-3;
    background: white;
    font-family: inherit;

    span {
      margin-right: 6px;
    }
  }
  .no-border {
    border: none !important;
  }
  .section-panel-link {
    padding: 5px 18px;
    display: flex;
    background: white;
    justify-content: space-between;
    vertical-align: middle;
    border-bottom: 1px solid $grey-4;
    color: $grey-6;

    &:after {
      content: '\e936';
      font-family: 'icomoon';
      font-size: 9px;
      color: $grey-6;
      vertical-align: middle;
      align-self: center;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
.section-panel-link {
  display: flex;
  justify-content: space-between;

  &:after {
    content: '\e936';
    font-family: 'icomoon';
  }
  &:hover {
    color: $pink;
  }
}

// ==================================== COMPONENTS =============================
.section-title {
  background: none;
  font-size: 1.5em;
  border: none;
}

.button {
  background: $pink;
  height: 48px;
  text-align: center;
  padding: 0 30px;
  border: 0;
  color: white;
  font-family: 'roboto';
  position: relative;
  z-index: 0;
  transition: background-color 300ms;
  vertical-align: middle;
  display: block;
  margin: auto;
  max-width: 360px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    background: $pink-hover;
    transition: background-color $tm;
  }

  .button--inline {
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    min-width: 110px;
    color: white !important;
  }

  .button--green {
    background: $green;

    &:hover,
    &:active {
      background: $green-hover !important;
    }
  }

  &:disabled {
    //background: $grey-6;
    opacity: 0.65;
    cursor: not-allowed;
  }
}
.btn-secondary {
  background-color: $pink;
  &:disabled,
  &.disabled {
    background-color: $pink;
    color: #fff;
    opacity: 0.65;
  }
}
.button--inline {
  display: inline-block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  min-width: 110px;

  &:disabled {
    background: $grey-6;
    cursor: not-allowed;
  }
}

.button--w100{
  width: 100% !important;
}

.button--green {
  background: $green !important;

  &:hover,
  &:active {
    background: $green-hover !important;
  }

  &:disabled {
    background: $grey-6;
    cursor: not-allowed;
  }
}

.btn-group {
  display: flex;
  justify-content: space-between;

  .button {
    line-height: normal;
    background: white;
    color: $pink;
    border: 1px solid $pink;

    &:hover,
    &:active,
    &:focus {
      background: darken($pink, 5%);
      color: white;
    }
    &.active {
      background: $pink;
      color: white;
    }
  }
}

label {
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
}

.longtext {
  line-height: normal;
}

// ==================================== BODY PART ==============================
body {
  background: $grey-1 !important;
}

.content-container {
  height: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
}

.body {
  margin-top: 1rem;
}

.container {
  display: flex;
  margin: auto;
  position: relative;
  overflow: auto;
  background: $grey-2;
  overflow-x: hidden;
  min-height: 100vh;
  padding: none;
}

// ==================================== END BODY PART ==============================

// ==================================== ICON STYLE ==============================
.Brand {
  width: 60px;
  height: 60px;
}

.Help::before {
  font-family: 'icomoon';
  font-size: 2rem;
}

.IconLink {
  color: $grey-6;
  transition: all ease-in-out 150ms;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-left: 1.4rem;

  &:hover,
  &:focus {
    color: $pink;
    text-decoration: none;
  }
  &.Badge {
    position: relative;

    &::after {
      width: 20px;
      height: 20px;
      border: solid 2px white;
      background: $pink;
      color: white;
      font-size: 10px;
      font-family: 'roboto-bold';
      text-align: center;
      line-height: 17px;
      position: absolute;
      bottom: 12px;
      border-radius: 20px;
      content: attr(data-badge);
    }
    @media (min-width: 992px) {
      &.Badge {
        &::after {
          left: 20px;
        }
      }
    }
  }
}
.Icon,
.Icon:visited {
  &::before {
    font-family: 'icomoon';
    font-size: 1.2rem;
  }
  width: 2rem;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;

  &.Dashboard::before {
    content: '\e900';
  }

  &.Settings::before {
    content: '\e916';
  }

  &.Assistance::before {
    content: '\e901';
  }

  &.Notifications::before {
    content: '\e902';
  }

  &.Logout::before {
    content: '\e9b6';
  }

  &.IconBrian::before {
    content: '\e926';
  }

  &.Badge {
    position: relative;

    &::after {
      width: 20px;
      height: 20px;
      border: solid 2px white;
      background: $pink;
      color: white;
      font-size: 10px;
      font-family: 'roboto-bold';
      text-align: center;
      line-height: 17px;
      position: absolute;
      bottom: 12px;
      border-radius: 20px;
      content: attr(data-badge);
    }
  }
  @media (max-width: 576px) {
    &.Badge {
      &::after {
        left: 20px;
      }
    }
  }
}

// ==================================== HEADER PART ==============================
.menuTxt {
  white-space: nowrap;
}
.header-container {
  background: white;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 0;
  margin-right: 0;
  position: fixed;
  // height: 75px;
  height: auto;
}

header {
  .icon-my-brian {
    font-size: 4rem !important;
    color: $pink;
  }

  .header__icon {
    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }

  .header-left {
    text-align: left;
    color: $grey-3;
    font-size: 34px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    padding-left: 1.5rem;
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1.5rem;
  }

  .display-assistance-right {
    justify-content: flex-end;
  }

  .icon-brian {
    margin: auto;
    font-size: 36px;

    &:hover {
      text-decoration: none;
    }
  }

  .Back,
  .Website {
    font-family: 'icomoon';
    line-height: 0;
    font-size: 0;
    color: transparent;

    &:hover {
      text-decoration: none;
      color: lighten($color: $pink, $amount: 5);
    }
  }

  .Website::before {
    content: '\e919';
    color: $pink;
    font-size: 3.8rem;
    line-height: 1;

    &:hover {
      text-decoration: none;
      color: lighten($color: $pink, $amount: 5);
    }
  }

  .Back::before {
    font-size: 25px;
    content: '\e93a';
    line-height: 55px;
    color: $pink;

    &:hover {
      text-decoration: none;
      color: lighten($color: $pink, $amount: 5);
    }
  }

  .active,
  .active:visited {
    color: $pink;
  }
  nav,
  .navbar-nav {
    li {
      margin-bottom: 0.7rem;
    }
  }

  .react-toggle {
    touch-action: pan-x;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    bottom: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #ff5f6b;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #ff5f6b;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #19ab27;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: #128d15;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #ff5f6b;
    border-radius: 50%;
    background-color: #fafafa;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19ab27;
  }

  .availability {
    color: #a1a1a1;
    list-style: none;
  }

  .availability__label {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.4rem;
  }

  .availability__toggle {
    margin-left: 7px;
    margin-top: 5px;
  }

  .availability span {
    vertical-align: middle;
  }

  .header__available {
    color: $pink;
    font-size: 14px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    font-size: 34px;
  }

  .icon-arrow-left {
    font-size: 24px;
  }

  .icon-arrow-right {
    font-size: 24px;
  }

  .icon-chat {
    font-size: 42px;
  }

  .icon-brian {
    font-size: 36px;
  }

  .text {
    font-size: 16px;
  }

  .has-arrow {
    &:after {
      content: '\e936';
      font-family: 'icomoon';
      font-size: 12px;
      color: $pink;
      margin-left: 2px;
      vertical-align: middle;
    }
  }
}

// ==================================== END HEADER PART ==============================
// ==================================== REACTSTRAP NAVLINK STYLE  ==========================
.nav-tabs .nav-link {
  color: $grey-6;
}

// ==================================== REACTSTRAP MODAL STYLE ==============================
.modal-dialog {
  h5.modal-title {
    font-size: 1.4rem;
    color: $pink;
  }

  .close {
    color: $pink;

    &:hover {
      color: $pink-hover;
      transition: all ease 0.3s;
    }
  }
}
.section-invoice {
  padding: 10px 15px;
  padding: 20px 25px;
  border-bottom: 1px solid #e5e5e5;
  background: white;
}
.section-invoice__row {
  display: flex;
}
.section-invoice__row-label {
  margin-left: auto;
  font-weight: bold;
  padding-right: 2em;
}

.helloName {
  color: $blue;
  margin-left: 20px;
}

h4 {
  color: $grey-8;
  font-size: 1.2rem;
  font-weight: normal;
}

// ==================================== CAPTCHA STYLE ==============================
#reload_href {
  font-size: 0.8rem;
}

.smallRemark {
  font-size: 0.8rem;
}

body {
  background: #4684a8 !important;
}
.container {
  background: white !important;
  margin-top: 70px;
  padding-bottom: 50px;
}

.nav-link.active {
  color: #ff5f6b !important;
  background-color: #fff !important;
  border: none;
  border-bottom: 4px solid #ff5f6b !important;
}
.nav-link:hover {
  color: #ff5f6b !important;
  background-color: #fff !important;
  border: none;
}

.bold {
  font-weight: bold;
}

.blue {
  color : #1a496b
}

.inverted-blue {
  color : #1a496b;

  &:hover {
    color : white;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadPlus {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  margin-left: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    > span {
      color: white
    }
    > svg {
      color: white
    }
  }
}

.w-full {
  width: 100%;
}

.uploader {
  padding: 8px 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;

  > span {
    margin-right: 15px;
  }

  &:hover {
    > span {
      color: white
    }
    > svg {
      color: white
    }
  }
}

.form-group {
  margin : 0px !important
}

.widget > .form-group {
  flex: 1
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.tooltipIcon {
  cursor: help;
  color: $blue;
  :hover {
    color : $pink !important
  }
}

.customTheme {
  color: white !important;
  border-radius: 10px !important;
  background-color: $pink !important;
  padding: 15px !important;
  width: 400px !important;
  opacity: 1!important;

  * {
    color: white!important;
  }

  &.place-top {
  &:after {
  border-top-width: 0px !important;

  }
  }
}

.orderTitle {
  font-weight: bold;
  font-size: 22px;
  color: $blue;
  margin-bottom: 10px;

  * {
    color: $blue;
  }
}

.sectionBox {
  padding: 15px 25px;
  margin-bottom: 20px;
  background: #F3F8FB 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start!important;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.space-between {
  justify-content: space-between;
}

.updateText {
  font-size: 16px;
}

.langage-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;


  span {
    color: $blue;
    font-style: italic;
    font-size: 16px;
    margin-right: 15px;
  }

  .l-text {
    margin-left: 15px;
  }
}


/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .l-text {
    margin-left: 0px!important;
  }

  .m-t {
    margin-top: 10px;
  }

}

.share-flex {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .l-text {
    margin-left: 0px!important;
  }

  .m-t {
    margin-top: 10px;
  }

  .share-flex {
    width: 100%!important;
  }

}

.basic-box {
  background-color: white!important;
  border-radius: 10px!important;
  box-shadow: 0px 3px 6px #00000029!important;
  padding: 20px!important;
  margin-bottom: 20px!important;
}

.mr-s {
  margin-right: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.button-green {
  font-weight: normal;

  padding: 0.5rem 3rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  border: 1px solid $green;
  cursor: pointer;
  background-color: $green;
  color: $white;
  border-radius: 15px;

  &:hover {
    color: $white;
    background-color: $green-hover;
    transition: all ease 0.3s;
  }
}

.expertiseBox {
  padding: 15px;
  border-radius: 15px;
  color: $grey-5;
  font-weight: bold;
  background-color: white;
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 10px;
}

.flex { 
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.blue {
  color: #1a496b;
}

.pink {
  color : #FE5F6B;
}

.w-full {
  width: 100%
}

.flex-wrap {
  flex-wrap: wrap;
}