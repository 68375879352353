@import '../style/variables';

.languageList__Item {
  cursor: pointer;
}

.canTranslate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.buttonAdd {
  font-weight: normal;

  margin: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  border: 1px solid $pink;
  cursor: pointer;
  background-color: $pink;
  color: $white;

  &:hover {
    color: $white;
    background-color: $pink-hover;
    transition: all ease 0.3s;
  }
}
