@import '../style/variables';

.card {
    background: #F4F8FB;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
}

.title {
    font-size: 1.4em;
    color: $blue;
    font-weight: bold;
}