@import "../../style/variables";

.Message {
  &:first-of-type {
    margin-top: auto;
  }

  margin-bottom: 25px;
  position: relative;
  font-size: 13px;
  width: 85%;

  overflow-wrap: break-word;
}

.Right {
  width: 75%;
  align-self: flex-end;
}

.Left {
  align-self: flex-end;
}

.Right .Text {
  &:before {
    border-top: 13px solid $grey-2;
    border-left: 28px solid rgba(0, 0, 0, 0);
    border-radius: 0px;
    position: absolute;
    bottom: -8px;
    right: 0;
    content: "";
  }

  background: $grey-2;
  border-radius: 10px 10px 0 10px;

  &:after {
    bottom: -15px;
    right: 15px;
  }
}

.Left .Text {
  &:before {
    border-top: 13px solid $pink;
    border-right: 28px solid rgba(0, 0, 0, 0);
    border-radius: 0px;
    position: absolute;
    bottom: -8px;
    left: 0;
    content: "";
  }

  background: $pink;
  color: white;
  border-radius: 10px 10px 10px 0;

  &:after {
    bottom: -15px;
    left: 20px;
  }
}

.Text {
  padding: 17px;
  position: relative;
  width: 100%;

  &:after {
    position: absolute;
    content: attr(data-time);
    color: $grey-6;
    font-size: 10px;
  }
}

.Photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: -45px;
  bottom: 0;
  display: block;
  color: $pink;
  font-size: 35px;
}
