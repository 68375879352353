@import '../style/variables';

.FieldHolder {
  border: 1px solid $grey-3;
  max-width: 300px;
  margin: 15px;
}

.Description {
  display: flex;
  background: white;
  min-height: 57px;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: stretch;
  border-bottom: 1px solid $grey-3;

  i {
    flex-shrink: 0;
    margin: 10px;
  }

  div {
    flex-grow: 1;
  }
}

.Info {
  display: flex;
  border-bottom: 1px solid $grey-3;
  background: $grey-1;
  padding: 4px 5px;
  font-size: 13px;
  color: $dark;
  transition: all 0.1s;
}

.FileName {
  flex: 1;
  overflow-wrap: anywhere;
}

.Uploading {
  font-style: italic;
}

.Words {
  font-size: 13px;
  color: $grey-6;
  margin-right: 10px;
}

.Error {
  background-color: $pink;
}

.Remove {
  font-size: 13px;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: none;
  color: $pink;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    color: darken($color: $pink, $amount: 10);
  }
}

.Link:hover {
  cursor: pointer;
}
