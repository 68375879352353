@import "../style/variables";

.OrderInfo {
    flex: 0 1 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    color: $grey-8;
    padding-left: 15px;
}
.UploadInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    font-size: 10px;
    text-align: center;
    padding: 5px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    i {
        margin: 0 auto 0 0;
    }
}