@import "../style/variables";

.text {
    color : $blue;
    margin : 0px;
}

.title {
    color : $blue;
    font-size: 26px!important;
    @media (max-width: 500px) {
          font-size: 20px!important;
    }
}