@import '../style/variables';

.Wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: $grey-1;
  margin: 0.5rem 0;
  padding: 0.3rem;
  min-height: 5rem;
}
.Row {
  min-width: 100%;
  align-self: center;
  align-items: center;
}
.givenName {
  font-weight: bold;
  color: $pink;
  padding-left: 1.3rem;
}
.Remove {
  font-size: 13px;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: none;
  color: $pink;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: darken($color: $pink, $amount: 10);
  }
}
