@import "../style/variables";

.body {
  display: flex;
  min-height: 500rem;
  flex-direction: column;
}

.subHeader {
  > div {
    padding-top: 2rem;
  }
}

.noMarginY {
  margin-bottom: 0;
  margin-top: 0;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}
