@import '../style/variables';

.tooltip-inner {
  padding: 0.5rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid $grey-7;
  box-shadow: 0.2rem 0.2rem 0.3rem $grey-1;
}

.none {
  .tooltip {
    .tooltip-inner {
      color: $dark;
      border: 1px solid $dark;
      background-color: #fff;
      border: 1px solid $grey-7;
      box-shadow: 0.2rem 0.2rem 0.3rem $grey-1;
    }
  }

  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $dark;
  }

  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: $dark;
  }
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: $dark;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: $dark;
  }
}
.info {
  .tooltip {
    .tooltip-inner {
      color: $blue;
      border: 1px solid $blue;
      background-color: #fff;
      border: 1px solid $blue-hover;
      box-shadow: 0.2rem 0.2rem 0.3rem $grey-1;
    }
  }

  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $blue;
  }

  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: $blue;
  }
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: $blue;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: $blue;
  }
}
.warning {
  .tooltip {
    .tooltip-inner {
      color: $dark;
      background-color: #fff;
      border: 1px solid $orange;
      box-shadow: 0.2rem 0.2rem 0.3rem $grey-1;
    }
  }

  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $orange;
  }

  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: $orange;
  }
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: $orange;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: $orange;
  }
}
.success {
  .tooltip {
    .tooltip-inner {
      color: $dark;
      border: 1px solid $green;
      background-color: #fff;
      border: 1px solid $green-hover;
      box-shadow: 0.2rem 0.2rem 0.3rem $grey-1;
    }
  }

  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $green;
  }

  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: $green;
  }
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: $green;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: $green;
  }
}
.danger {
  .tooltip {
    .tooltip-inner {
      color: $pink;
      border: 1px solid $pink;
      background-color: #fff;
      border: 1px solid $pink-hover;
      box-shadow: 0.2rem 0.2rem 0.3rem $grey-1;
    }
  }

  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $pink;
  }

  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: $pink;
  }
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: $pink;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: $pink;
  }
}
