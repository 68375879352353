@import '../style/variables';

.formMask {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.formContainer {
  display: flex;
  min-width: 300%;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;

  .stepOne,
  .stepTwo,
  .stepThree {
    min-width: 33.333%;
  }
}

.step0 {
  left: 0%;
  transition: all ease-in 0.5s;
}

.step1 {
  left: -100%;
  transition: all ease-in 0.5s;
}

.step2 {
  left: -200%;
  transition: all ease-in 0.5s;
}
.stepThree {
  p {
    span {
      font-size: 0.6rem;
    }
  }
}
.languageList_item {
  padding: 0.5rem 1rem;
  text-align: center;

  .languageList_elt {
    padding: 0.5rem;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    border: 1px solid $pink;
    border-radius: 0.3rem;

    &:hover,
    &.selected {
      background-color: $pink-hover;
      color: $grey-1;
      transition: all ease 0.3s;
    }
  }
}
.modalOnModal {
  width: 40%;
}
