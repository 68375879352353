@import "../style/variables";

.Container {
  display: flex;
  align-items: stretch;
  margin-right: 0px !important;
  margin-left: 0px !important;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: stretch;
  border-radius: 20px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.Left {
  &.Centerline .Header {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.Centerline .LeftContent {
  border-right: 1px solid rgb(26, 74, 107);
}

.Left,
.Right {
  flex: 1;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding-right: 0 !important;
  padding-left: 0 !important;
  justify-content: center;
}

.Header {
  padding: 5px;
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  // background-color: $pink;
  background-color: rgb(26, 74, 107);
}

.Centerline {
  .LeftContent,
  .RightContent {
    padding: 15px;
    background-color: white;
    flex-grow: 1;
  }
}
.PriceContent,
.DateContent {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 400px) {
    padding: 10px;
  }
}
.For {
  text-align: center;
  color: #1a496b;
  margin: 0px 20px;

  @media (max-width: 450px) {
    margin: 0px 5px;
  }
}
