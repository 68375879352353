@import "../style/variables";

.Container {
  padding: 23px 0px;
  position: relative;
}

.Tips {
  padding-top: 8px;
  color: #444444;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 11px;
  text-align: center;

  span {
    display: inline-block;
    user-select: none;
  }
  @media (max-width: 420px) {
    display: none;
  }
}

.Fast {
  float: left;
  color: $blue;
}

.Help {
  color: $blue;

}

.Cheap {
  float: right;
  color: $blue;

}

@keyframes slider {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-15%);
  }
  75% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(0);
  }
}
