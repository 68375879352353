@import "../style/variables";

.DeadlineContainer {
  display: flex;

  &::after {
    font-family: "icomoon";
    color: #525252;
    vertical-align: middle;
    font-size: 1.1rem;
  }
}

.PriceContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  &::after {
    font-family: "icomoon";
    color: #525252;
    vertical-align: middle;
    font-size: 33px;
  }
}

.Prices {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  align-items: center;
  span {
    font-size: 70%;
  }
}
.Price {
  font-size: 16px;
  color: $blue;
  font-weight: bold;
  text-align: center;
  @media (max-width: 360px) {
    font-size: 0.85rem;
  }
}

.PriceWithTax {
  font-size: 16px;
  font-weight: bold;
}

.PriceWithTax,
.HT {
  font-size: 1rem;
  font-style: italic;
  @media (max-width: 360px) {
    font-size: 0.85rem;
  }
}

.VATExempt {
  color: $pink;
}
.PriceInit {
  font-size: 15px;
  font-weight: bold;
  color: $blue;
  text-align: center;
}
