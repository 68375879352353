@import "../style/variables";

.Link {
  padding: 5px 18px;
  display: flex;
  background: white;
  justify-content: space-between;
  border-bottom: 1px solid $grey-4;
  color: $grey-6;
  width: 100%;

  &::after {
    content: "\e936";
    font-family: "icomoon";
    font-size: 9px;
    color: $grey-6;
    vertical-align: middle;
    align-self: center;
  }

  &:hover {
    text-decoration: none;
  }
}
