@import "../style/variables";

.Container {
  margin: 40px 0 10px;
  display: flex;
  justify-content: space-between;

  /*   a {
    display: block;
  } */
}

.Divider {
  flex-grow: 1;
  border-top: 2px solid $pink;
  height: 1px;
  border-radius: 1px;
  margin: 8px 15px;
}

.Complete,
.Complete:visited,
.Incomplete {
  z-index: 2;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: $pink;

  span {
    border-radius: 50%;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 27px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 5px;
  }
}

.Complete {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    background: $pink;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Incomplete {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $blue;
  span {
    background: white;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Text {
  display: flex;
  text-align: center;
}
