.Box {
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 15px 15px 25px;
    margin-left: 20px;
    min-width: 350px;

    p {
        margin-bottom: 5px;
        font-weight: bold;
    }
}

@media screen and (max-width: 1000px) {
    .Box {
        min-width: 300px!important;
        margin-left: 0px!important;
        margin-top: 10px;
    }
}