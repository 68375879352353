@import '../style/variables';



.Card {
  background: #F4F8FB;
  position: relative;
  margin: 20px;
  border-radius:20px;
  padding: 15px 25px;
  @media (max-width: 720px) {
    margin: 20px 0;
  }
}

.Rounded {
  border-radius: 20px;
  box-shadow: 0 1px 3px 1px rgba(204, 204, 204, 0.5);
  .Filled {
    border-radius: 20px;
    padding: 40px;
  }
  margin-top: 40px;
  margin-bottom: 40px;
}

.Title {
  // background: white;
  font-weight: bold;
  font-size: 1.4rem;
  color: $blue;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  div::before {
    font-family: 'icomoon';
    margin-right: 1em;
    font-size: 16px;
    content: attr(data-icon);
  }
}

.TitleNoIcon {
  // background: white;
  font-weight: bold;
  font-size: 1.4rem;
  color: $blue;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.Clickable {
  color: $pink;
  &:hover {
    cursor: pointer;
  }
}
.Filled {
  // background: white;
  padding: 20px;
}

.Centre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.Collapsed:after {
  font-family: 'icomoon';
  content: ' \e933';
}

.Expanded:after {
  font-family: 'icomoon';
  content: ' \e934';
}
