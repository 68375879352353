@import "../style/variables";

.Container {
    margin-top: 3em;
    background: $pink;
    padding: 3em;
    border-radius: 30px;
    color: white;

    a {
        &:hover {
            font-weight: bold;
        }
        &:before {
            content: "\2190";
            padding-right: 3px;
        }
    }
}