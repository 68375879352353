@import '../style/variables';

// .Upload {
//   margin: 15px;
// }

.Alert {
  display: inline-block;
  border-radius: 50%;
  background-color: $pink;
  color: white;
  font-size: 10px;
  line-height: 15px;
  padding-left: 5px;
  width: 15px;
  height: 15px;
}

.ButtonBar {
  button {
    margin: 15px;
  }
}
.Feedback {
  dl {
    margin: 0;
  }
  dt {
    margin-top: 15px;
  }
  dd {
    margin: 0;
  }
}
