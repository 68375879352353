@import "../style/variables";

.Container {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.ErrorBox {
  padding: 2em;
  border-radius: 15px;
  background: $pink;
  text-align: center;
  color: white;
  position: relative;
  z-index: 2;

}

.ErrorMessage {
  padding: 1em;
}


.ErrorReporter {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'roboto', sans-serif;
  font-size: inherit;
}

.ErrorReporter:hover,
.ErrorReporter:focus {
  text-decoration: underline;
}

.Logo {
  color: white;
  font-size: 75px;
}
