@import '../style/variables';

.OrderInfo {
  flex: 0 1 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  color: $grey-8;
}
.UploadInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  font-size: 10px;
  text-align: center;
  padding: 5px;

  img {
    margin: 0 auto;
  }
}
