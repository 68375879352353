@import "../../style/variables";

.rating {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.rating--1 div:nth-child(-n + 1):before {
  content: "\e912";
}
.rating--2 div:nth-child(-n + 2):before {
  content: "\e912";
}
.rating--3 div:nth-child(-n + 3):before {
  content: "\e912";
}
.rating--4 div:nth-child(-n + 4):before {
  content: "\e912";
}
.rating--5 div:nth-child(-n + 5):before {
  content: "\e912";
}
.rating:hover .rating__star:before {
  content: "\e912";
}
.rating__star {
  cursor: pointer;
  font-family: "icomoon";
  font-size: 32px;
  color: #f7af2c;
  padding: 0 5px;
}
.rating__star:before {
  content: "\e918";
}
.rating__star:before:hover {
  content: "\e912";
}
.rating__star:hover ~ .rating__star:before {
  content: "\e918" !important;
}
.rating__star-readonly {
  cursor: default;
  font-family: "icomoon";
  font-size: 32px;
  color: #f7af2c;
  padding: 0 5px;
}
