@import "../style/variables";

.UploadContainer {
  :hover {
    background-color: #1a496b;
    cursor: pointer;
  }
}

.WidgetStep {
  border: 1px solid $blue;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 24px;
  text-align: center;
  color: $grey-1;
  font-size: 0.85em;
  background-color: $blue;
}

.StepTitleWidget {
  color: $blue;
}

.WidgetHeader {
  background: #ff5f6b;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 25px;
  text-align: center;
  font-size: 20px;
  font-family: "Roboto";
  margin-top: -60px;
}

.Or {
  text-transform: uppercase;
  font-family: "Roboto";
  color: $blue;
  font-size: 26px;
  margin-top: 3px;
  text-align: center;
  font-weight: bold;
}

.WordingAction {
  font-family: "Roboto";
  font-weight: bold;
  color: $blue;
  font-size: 16px;
}

.WordingSubAction {
  text-align: center;
  font-size: 13px;
}

.WidgetSectionTitle {
  background: white;
  padding: 16px 20px;
  font-size: 18px;
  font-family: "roboto";
  font-weight: bold;
  color: $blue;
}

.WidgetErrorUploader {
  font-size: 13px;
  font-family: "roboto";
  color: red;
  text-align: center;
}

.WordsResult {
  margin: 5px;
}

.Button {
  background: $blue;
  padding: 0.7rem;
  font-size: 1rem;
  border: 0;
  color: white;
  font-family: "roboto";
  transition: background-color 300ms;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  &:disabled {
    background: #a1a1a1;
    cursor: not-allowed;
  }
  @media (max-width: 320px) {
    font-size: 0.9rem;
    padding: 0.2rem;
  }
}

.WidgetContainer {
  outline: none;
  padding: 20px 20px 20px 60px;
  :focus {
    outline: 0;
    outline-color: transparent;
    outline-style: none;
  }
  @media (max-width: 400px) {
    padding: 15px 15px 15px 30px;
  }
}

.WidgetSection {
  padding: 15px 25px;
  margin-bottom: 20px;
  background: #F3F8FB 0% 0% no-repeat padding-box;
  border-radius: 20px;
  @media (max-width: 400px) {
    * {
      font-size: 16px!important;
    }
    > span > div {
      font-size: 20px!important;
 
    }
  }
}

.ContainerInputWording {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.Info {
  padding: 5px;
  position: absolute;
  top: -6px;
  right: -5px;
}

.ButtonDevis {
  background: #1a496b;
  padding: 0.5rem;
  font-size: 0.8rem;
  border: 0;
  color: white;
  font-family: "roboto";
  transition: background-color 300ms;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  width: 50%;
  &:disabled {
    background: #a1a1a1;
    cursor: not-allowed;
  }
}

.ScrollAttachements {
  max-height: 30px;
  overflow-y: scroll;
  position: relative;
  bottom: 8px;
}

.divider {
  height : 1px;
  width : 200px;
  background-color: $blue;
  margin :0px 20px 0px 20px;
}

.successContainer {
  z-index: 2;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  //position: absolute;

  p {
    font-weight: bold;
    color: #5FB856;
    font-size: 15px;
    margin-bottom: 5px;
  }

  span {
    color: #5FB856;
    font-size: 15px;
  }
}

.stepCircle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width : 50px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  background: #FE5F6B 0% 0% no-repeat padding-box;
  top: -7px;
  left : -60px;
  z-index: 3;

  span {
    color: white;
    font-size: 27px;
  }
  @media (max-width: 400px) {
    height: 40px;
    width: 40px;
    left : -50px;

  }
}

.contact {
  font-size: 14px;
  font-style: italic;
  color: $blue;
  margin-top: 10px;
  cursor: pointer;
}