@import "../../style/variables";
@import "../../style/animations";

.DragPrompt {
  border: 1px dashed #1a496b;
  border-radius: 16px;
  color: #444444;
  width: 100%;
  text-align: center;
  padding: 16px;
  transition: all ease-in 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue;
  font-size: 14px;

  img {
    margin-right: 1em;
  }

  &:hover {
    background-color: #4783a6;
    color: white;
    cursor: pointer;
  }
}

.DragPromptWidget {
  border-radius: 50%;
  transition: all ease-in 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  width: 75px;
  height: 75px;
  box-shadow: 0 1px 3px 1px rgba(204, 204, 204,  0.3);

  &:hover {
    background-color: #4783a6;
    color: white;
    cursor: pointer;
  }
}

.DragPromptTranslator {
  border: 1px dashed #1a496b;
  border-radius: 16px;
  color: #444444;

  text-align: center;
  padding: 16px;

  p {
    font-size: 10px;
    line-height: 11px;
    font-style: italic;
    font-family: Roboto, sans-serif;
    margin: 0;
  }

  transition: all 0.2s ease-in;

  &:hover {
    background-color: #1d648b;
    cursor: pointer;
    color: white;
  }
}

.UploadingContainer {
  width: 100%;
  height: 72px;
  padding-top: 15px;
}
.Accept .DragPrompt {
  background: #1a496b;
  color: white;
  @include flash(infinite, $duration: 5s);
}

.Disabled {
  opacity: 0.5;
}
.Button {
  transition: all 0.2s ease-in;
  border-radius: 8px;
  background-color: #1a496b;
  margin-right: 24px;

  &:hover {
    background-color: #1d648b;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.7px;
    line-height: 14px;
    text-align: center;
    margin: 0;
    padding: 8px 32px;
  }
}

.Grow {
  flex-grow: 1;
  text-align: center;

  color: #444444;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 19px;
  padding: 0 1em;
}

.Reject .DragPrompt {
  position: relative;
  background: $pink;

  &:after {
    content: "x";
    position: absolute;
    color: white;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 50px;
    line-height: 70px;
    text-align: center;
    @include flash(infinite);
  }
}

.Error {
  color: $pink;
  text-align: center;
  margin: 20px;
}

.ProgressHolder {
  border: 1px solid $pink;
  width: 200px;
  height: 1em;
  margin: 0 auto;
}

.ProgressBar {
  background: $pink;
  display: block;
  height: 100%;
}

.Uploading {
  text-align: center;
  color: $pink;
  @include flash(infinite, 5s);
}

.WidgetUploader {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: #1a496b;
  }
}

