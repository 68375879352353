@import "../style/variables";

.H1 {
    color: $blue;
    padding-left: 15px;
}

.BigMargin {
    margin-top: 40px;
    margin-bottom: 40px;
    div {
        padding: 40px;
    }
}