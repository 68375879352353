@import '../style/variables';

.Container {
  padding: 10px 15px;
  //border-bottom: 1px solid $grey-5;
  display: flex;
}

.Star {
  font-family: 'icomoon';
  color: $orange;
  font-size: 32px;
  padding: 0 5px;

  &:before {
    content: '\e918';
  }
}

.Editable .Star:hover {
  cursor: pointer;
}

.Filled,
.Editable .Star:hover {
  &:before {
    content: '\e912';
  }
}
