@import '../style/variables';
.Row {
  min-width: 100%;
  align-self: center;
  align-items: center;
}
.Wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: $grey-1;
  margin: 0.5rem 0;
  padding: 0.3rem;
  min-height: 5rem;
}

.invoiceId {
  font-weight: bold;
  color: $pink;
  flex-grow: 1;
  padding-left: 1.3rem;
}
