@import './variables';
$theme-colors: (
  'secondary': $grey-6,
  'danger': $pink,
  'success': $green,
);
@import '~bootstrap/scss/bootstrap.scss';
.alert-success {
  color: $white;
  background-color: $green;
}
.alert-danger {
  color: $white;
  background-color: #ff5f6b;
}
