@import "../style/variables";

.Container {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: white;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px;
  color: #a1a1a1;
}

.AwaitingContainer {
  @extend .Container;
  &:before {
    content: '\e931';
    font-family: 'icomoon';
    color: #a1a1a1;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 15px;
    margin-left: 19px;
  }
}

.DeleteButton {
  color: #a1a1a1;
  float: right;
  padding: 5px 5px 0 0;
  &:hover, &:focus {
    color: $pink;
    text-decoration: none;
  }
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  margin-left: 5px;
  font-family: 'roboto', sans-serif;
  font-size: inherit;
}

.Avatar {
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 50%;
  vertical-align: middle;
  background: $grey-6;
  width: 30px;
  height: 30px;
}
