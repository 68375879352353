@import "../../style/variables.scss";

.title {
    color: $blue;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;
}

.help {
    color: $blue;
    cursor: pointer;
}

.messageContainer {
    padding: 10px;
    border-radius: 30px;
    background-color: $pink;
    color: white;
    flex: 1;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;


    &:hover {
        filter: brightness(110%);
    }
}

.boxTitle {
    color: $pink;
    font-weight: bold;
    font-size: 15px;
}