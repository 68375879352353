.section-options {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: stretch;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  @media (max-width: 576px) {
    display: block;
  }
  & .discounted {
    text-decoration: line-through;
    margin-right: 10px;
  }

  .section-options__discountReason {
    background: $pink;
    padding: 5px 5px;
    color: white;
    font-size: 14px;
    text-align: center;
    flex: 0 1 100%;
  }
  .section-options__price-block,
  .section-options__from-block {
    flex: 0 1 50%;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    .section-options__title {
      border-left: 1px solid $pink;
    }
  }
  .section-options__price-block,.section-options__timing-block {
    .section-options__title {
      border-left: 1px solid #1a4a6b;
      background-color: #1a4a6b;
    }
  }

  .section-options__timing-block,
  .section-options__to-block {
    flex: 0 1 50%;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  .section-options__title {
    background:$pink;
    padding: 5px 5px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .section-options__price {
    padding: 15px 15px;
    font-size: 24px;
    font-family: "roboto";
    color: $dark;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &:after {
      content: "\e930";
      font-family: "icomoon";
      color: $dark;

      vertical-align: middle;
      font-size: 22px;
    }
    > div {
      flex: 1 1 100%;
    }
  }

  .section-options__to {
    padding: 15px 15px;
    font-size: 24px;
    color: $dark;
    flex: 1 1 auto;
    display: flex;
    font-family: "roboto";
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-left: 1px solid $pink;
    @media (max-width: 576px) {
      border-left: none;
    }
  }

  .section-options__from {
    padding: 15px 15px;
    font-size: 24px;
    font-family: "roboto";
    color: $dark;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .section-options__prices-inner,
  .section-options__to-inner,
  .section-options__from-inner {
    display: inline-block;
    margin: auto;
    text-align: left;
  }

  .section-options__price-ht {
    font-size: 22px;
    font-weight: 600;
    &:after {
      content: "  HT";
      font-size: 70%;
    }
  }

  .section-options__price-ttc {
    font-size: 17px;
    font-style: italic;
    &:after {
      content: "  TTC";
      font-size: 70%;
    }
  }

  .section-options__timing {
    padding: 15px 15px;
    font-size: 24px;
    font-family: "roboto";
    color: $dark;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid #1a4a6b;

    &:after {
      content: "\e931";
      font-family: "icomoon";
      color: $dark;
      vertical-align: middle;
      font-size: 33px;
    }
    > div {
      flex: 1 1 100%;
    }
  }
  .section-options__date {
    font-size: 22px;
    font-weight: 600;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  .section-options__time {
    font-size: 17px;
    font-style: italic;
  }

  .section-options__datetime-inner {
    display: inline-block;
    margin: auto;
    text-align: center;
  }
}

.flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  color: $blue;
  font-size: 16px;
  img {
    vertical-align: text-top;
    margin-right: 5px;
  }
}
