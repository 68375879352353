@import "../style/variables";

.Container {
  display: flex;
  //flex-direction: column;
  justify-content: center;
  flex: 1 1 100%;
  align-items: center;
  color: $blue;
}

.Date {
  font-size: 16px;
  margin-right: 3px;
  color: $blue;
  font-weight: bold;
  @media (max-width: 360px) {
    font-size: 0.85rem;
  }
}

.Time {
  font-size: 16px;
  font-style: italic;
  color: $blue;
  @media (max-width: 360px) {
    font-size: 0.85rem;
  }
}

.Title {
  font-size: 0.8rem;
  margin-right: 5px;
  font-style: italic;
}
