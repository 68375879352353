@import '../../style/variables'; 

.SourceLanguageDetection,
.DestinationLanguage {
  font-size: 14px;
  label {
    color: $blue;
    font-size: 16px;
  }
  span span {
    font-weight: bold;
  }

  select {
    width: 160px;
    margin-left: 10px;
  }
}

.SelectedLng {
  text-transform: lowercase;
  font-weight: bold;
}
