@import "../style/variables";

.UploadContainer {
  padding: 15px;
}

.ErrorMessage {
  padding: 10px;
  border-radius: 10px;
  background-color: #FE5F6B;
  color: white;
  display: flex;
  align-items: center;
  width: 80%;
  font-size: 14px;
  box-shadow: 0px 3px 6px #00000029;
}

.styledUploadField {
  display: flex;
  align-items: center;
}

.WidgetSectionResult {
  font-family: "roboto";
  font-weight: bold;
  color: $blue;
  position: relative;
}