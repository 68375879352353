@import "../../style/variables";

.Header {
    display: flex;
    padding-top: 2px;
    align-items: center;
    button {
        padding: 1em;
    }

    span {
        text-align: center;
    }

}

.Phone {
    font-family: 'icomoon';
    color: $pink;
    padding: 15px;
    &::before {
        content: "\e942";
    }

    &:hover {
        color: lighten($color: $pink, $amount: 10%);
    }
}
