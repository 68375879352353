@import "../style/variables";

.button {
    background-color: $blue;
    color: white;
    transition : ease-out 0.2s;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #1A496B;
    border-radius: 10px;
    padding:10px 20px;
    font-size: 15px;

    &:hover {
        color : $blue;
        background-color: white;
    }
}

.disabled {
    background-color: #A1A1A1;
    color: white;
    text-transform: uppercase;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding:10px 20px;
    font-size: 15px;
    border: none;
    cursor: not-allowed;
    opacity: 0.65;
}