@import '../style/variables';

.cross {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  border: 0.1rem solid $pink;
  cursor: pointer;
  color: $pink;

  background-color: $white;
  line-height: 1rem;

  &:before {
    content: '×';
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-size: 1rem;
    padding-left: 0.2rem;
  }

  &:hover {
    color: $pink-hover;
    background-color: $grey-1;
    border-color: $pink-hover;
    transition: all ease $tm;
    box-shadow: 0.1rem 0rem 0.1rem rgba(0, 0, 0, 0.5);
  }
}
